import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import CourseworkDisclaimer from "../components/CourseworkDisclaimer"

const TinySNSPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>CSCE 438 - TinySNS</h1>
            {CourseworkDisclaimer()}
            <p>TinySNS is a project of the CSCE 438 (Distributed Systems) course at Texas A&amp;M University. The problem statement is to design a social network similar to Twitter. The emphasis is to work with shared state and consensus across multiple machines.</p>
            <p>I think the project is best understood by explaining the requirement first. A user should be able to log in on any machine. The user can see new messages, other users, who they are currently following, post a message to their followers, and see any messages from people they are following. To test this behavior, multiple (4+) Virtual Machines are used. At any point the server process may die, a daemon process on the same machine should detect this and attempt to restart the main process. Likewise, the daemon process may die, in which case the primary process should detect this and attempt to start a new daemon. Of course, a user should not see any of this behavior (a service router process/machine exists which never crashes).</p>
            <p>While all this is happening, the machines should be syncing the state of what the users are doing. When a process rejoins the pool, it needs to synchronize state with all the other machines in case one of them were to crash unexpectedly. Data storage wasn't really the goal: students are able to define/use whatever format they want. The message format is also left to the students, but most, myself included, choose to use Google RPC.</p>
            <p>This was probably the most helpful class I took at Texas A&amp;M, at least for what I ended up working on at <a href="/facebook">Facebook</a>. That being said, the code is a complete mess and I am 100% claiming "this is still used as a course project" privilege; this project will probably never end up on GitHub.</p>
            {FooterBar()}
        </div>
    )
}

export default TinySNSPage
